// TAMHSC variable overrides and defaults;
//
//
//
// A&amp;M colors https://brandguide.tamu.edu/colors.html
$aggie-maroon: #500000;
$aggie-gray: #343a40;
$light-gray: #f1f2f2;
$med-gray: #dee0e0;
$accent-red: #E4002B;
$accent-yellow: #FCE300;
$secondary-blue: #003C71;
$hover-blue: #098CC7;
$hover-maroon: #300000;

$body-bg: #fff;
$body-color: #111;
$transparent-white: rgba(255, 255, 255, 0.3);
$transparent-black: rgba(0, 0, 0, 0.6);
$transparent-black-80: rgba(0, 0, 0, 0.82);
$transparent-aggie-gray: rgba(52, 58, 64, 0.9);
$transparent-gray-80: rgba(52, 58, 64, 0.8);
$transparent-gray-50: rgba(52, 58, 64, 0.5);
$transparent-aggie-maroon: rgba(80, 0, 0, 0.9);
$transparent-accent-red: rgba(228, 0, 43, 0.8);

$headings-color: $aggie-maroon !default;

// Bootstrap default colors - here to make it easy to override as needed
$white:  #fff !default;

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:  #000 !default;

$grays: (
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900
) !default;

// colors defined as defaults in bootstrap - here so we can easily override if needed
// Jeremy can never decide on the best blue for links. Options tried:
// #007bff, #006bb8, #1955a5 (washington post), #1a0dab or #2200cc (both google), #0044cc (bing)
// some from example at https://colorswall.com/palette/3
$link-blue: #0474da !default; // hyperlink text
$blue:      #0474da !default; // other places blue is needed such as hover
$indigo:    #6610f2 !default;
$purple:    #6f42c1 !default;
$pink:      #e83e8c !default;
$red:       #d9534f !default;
$orange:    #fd7e14 !default;
$yellow:    #f0ad4e !default;
$green:     #5cb85c !default;
$teal:      #20c997 !default;
$cyan:      #5bc0de !default;
$faded:     #f7f7f7 !default;

$colors: (
  blue:      $blue,
  indigo:    $indigo,
  purple:    $purple,
  pink:      $pink,
  red:       $red,
  orange:    $orange,
  yellow:    $yellow,
  green:     $green,
  teal:      $teal,
  cyan:      $cyan,
  white:     $white,
  gray:      $gray-600,
  gray-dark: $gray-800
) !default;

$theme-colors: (
  primary:     $blue,
  secondary:   $gray-600,
  success:     $green,
  info:        $cyan,
  warning:     $yellow,
  danger:      $red,
  light:       $faded,
  dark:        $gray-800
) !default;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded:            false !default;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  false !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
$sizes: map-merge((
  25: 25%,
  33: 33%,
  50: 50%,
  66: 66%,
  75: 75%,
  100: 100%
), $sizes);
// stylelint-enable

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
// @TAMHSC - we added 6 through 10. Bootstrap is amazing.

$spacer: 1rem !default;
$spacers: (
  0:  0,
  1:  ($spacer * .25),
  2:  ($spacer * .5),
  3:  $spacer,
  4:  ($spacer * 1.5),
  5:  ($spacer * 3),
  6:  ($spacer * 4),
  7:  ($spacer * 5),
  8:  ($spacer * 6),
  9:  ($spacer * 7),
  10: ($spacer * 8)
) !default;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-tamhsc-headings:   "Tungsten A", "Tungsten B", "Tungsten", "Open Sans", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif !default;
$font-family-sans-serif:        "Open Sans", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif !default;
$font-weight-normal: 200 !default;

$line-height-base:        1.5 !default;
$line-height-1_75:        1.75 !default;
$line-height-2:           2   !default;
$headings-line-height:    1.1 !default;

$h1-font-size: 4rem !default;
$h2-font-size: 3rem !default;
$h3-font-size: 2.5rem !default;
$h4-font-size: 2rem !default;
$h5-font-size: 1.5rem !default;
$h6-font-size: 1rem !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;


// variables for container widths so we can use them elsewhere
$sm-width: 540px;
$md-width: 720px;
$lg-width: 960px;
$xl-width: 1400px;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  lg: $lg-width,
  xl: $xl-width
) !default;


// from _vivify.scss
$easeInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955);

$transition-fade: opacity 0.65s $easeInOutQuad;
