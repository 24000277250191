@import "_variables.scss";

#cludo-search-form {
  font-size: .875rem;
  font-weight: 200;
  width: calc(30vw + 120px);
  min-width: calc(250px + 120px);

  input[type=search] {
    width: 25vw;
    min-width: 250px;
    height: 40px;
    margin-top: 1rem;
    border: none;
    border-bottom: 1px solid $aggie-gray;
    margin-right: 10px;
    margin-left: auto;
    padding-left: 5px;

    &::placeholder {
      font-style: italic;
    }

    &:hover, &:focus, &:active {
      border: none;
      border-bottom: 1px solid $aggie-gray;
      box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.05);
    }
    &:focus-within {
      border: none;
      border-bottom: 1px solid $aggie-gray;
      box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.05);
    }
  }

  button {
    padding-left: 12px;
    padding-right: 12px;
    width: 95px;
    margin-right: 5px;
    background-color: $aggie-maroon;
    color: #fff;

    &:hover, &:focus, &:active {
      outline: 1px dotted $aggie-maroon;
      background-color: #350000;
      box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.05);
    }
  } // end button
}

#cludo-search-content-form label, #cludo-search-form label {
  position: absolute;
  left: -9999px;
  top: -9999px
}

#cludo-search-content-form .search_autocomplete, #cludo-search-form .search_autocomplete {
  position: absolute;
  top: 40px;
  background-color: #fff;
  border: 1px solid #efefef;
  width: 100%;
  z-index: 99999
}

#cludo-search-content-form .search_autocomplete h3, #cludo-search-form .search_autocomplete h3 {
  font-size: 93%;
  padding: 7px;
  border-bottom: 1px solid #efefef
}

#cludo-search-content-form .search_autocomplete h3:after, #cludo-search-form .search_autocomplete h3:after {
  content: ":"
}

#cludo-search-content-form .search_autocomplete li, #cludo-search-form .search_autocomplete li {
  border-bottom: 1px solid #efefef;
  padding: 7px;
  cursor: pointer;
  display: block;
  width: 100%
}

#cludo-search-content-form .search_autocomplete li:hover, #cludo-search-form .search_autocomplete li:hover {
  background-color: #efefef
}

#cludo-search-content-form .search_autocomplete li.active, #cludo-search-form .search_autocomplete li.active {
  background-color: #d6d6d6
}

#cludo-search-content-form .search_autocomplete li:last-child, #cludo-search-form .search_autocomplete li:last-child {
  border-bottom: 0
}

#cludo-search-content-form .search_autocomplete .autocomplete-separator, #cludo-search-form .search_autocomplete .autocomplete-separator {
  border-bottom: 1px solid #d6d6d6
}
